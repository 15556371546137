import blockedDomains from "./domain";

const getColor = (elementColor) => {
    if (elementColor?.indexOf('~') > -1) {
        elementColor = elementColor.slice(elementColor.indexOf('~') + 1).trim();
        elementColor = elementColor.slice(elementColor.indexOf('[') + 1, elementColor.indexOf(']'));
    }
    return elementColor;
};
const replaceCharAt = (str, index, replacement) => {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
};
const createUrl = (url, linkData) => {
    
    if (url && linkData) {
        let pgId = url.split('/')[1];
        let pgIndex = linkData.findIndex(function (nodes) {
            return nodes.node.uid === pgId;
        })
        if (pgIndex !== -1) {
            let pgDetails = linkData[pgIndex].node.data;
            let finalUrl;
            
            if (pgDetails.page_display_name) {
                finalUrl = `${pgDetails.page_path.text ? '/' + pgDetails.page_path.text : ''}/${pgDetails.page_display_name.text.split(" ").join("-")}/`
            }
            // else if (pgDetails.page_url) {
            //     finalUrl = `${pgDetails.page_path.text ? '/' + pgDetails.page_path.text : ''}/${pgDetails.page_url?.text?.split(" ").join("-")}/`
            // }
            else {
                finalUrl = `${pgDetails.page_path.text ? '/' + pgDetails.page_path.text : ''}/${pgDetails.resource_display_name.text.split(" ").join("-")}/`
            }
            return finalUrl.toLowerCase();
        }
        else {
            if (url === 'resources/blogs/streaming-analytics:-data-processing-options-on-google-cloud-platform') {
                url = "resources/blogs/streaming-analytics-data-processing-options-on-google-cloud-platform"
            }
            url = (url || "").split(":-").join("-");
            return url.includes('http') ? url : `/${url}/`;
        }
    }
};
const isParamPresentInURL = (name, url) => {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    return results ? true : false;
};
const getFormattedDate = (dateVal) => {
    let startDate = new Date(dateVal);
    let month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    let year = startDate.getFullYear();
    let date = startDate.getDate();
    let formattedDate = `${month} ${date}, ${year}`;

    return formattedDate;
};
const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
};



const checkCorporateEmail = async (emailID) => {
    const isBlocked = blockedDomains.some(domain => {
        return emailID.includes(domain)
    });

    if (!emailID || isBlocked) {
        return new Promise((resolve, reject) => {
            resolve({ valid: false })
        })
    }
    
    const myHeaders = new Headers();
    myHeaders.append('apikey', 'xmQ6w5NkAg1e1x5LMIwIFUqzNmCACrxQ');
    // let siteUrls = `https://api.apilayer.com/email_verification/check?email=${emailID}&catch_all=1`
    // let response = await fetch(siteUrls, {
    //     crossDomain: true,
    //     method: 'GET',
    //     redirect: 'follow',
    //     headers: myHeaders,
    // })
    //     .then(response => response.json())
    //     .then(response => {
    //         let domainName = emailID.substring(emailID.lastIndexOf("@") + 1);
    //         if (domainName === "gspann.com") {
    //             if (response.format_valid && !response.free && !response.disposable &&
    //                 response.mx_found && !response.role && response.score >= 0.25) {
    //                 return { valid: true };
    //             } else {
    //                 return { valid: false };
    //             }
    //         } else {
    //             if (response.format_valid && !response.free && !response.disposable &&
    //                 response.mx_found && !response.role && response.catch_all && response.score >= 0.25) {
    //                 return { valid: true };
    //             } else {
    //                 return { valid: false };
    //             }
    //         }
    //     });
    let response ={ valid: true };

    return response;
}

const mapCounterNumbers = (stats = []) => {
    return (stats || []).map(({ number_value, number_key }) => {
        const value = (number_value || {}).text || "";
        const label = (number_key || {}).text || "";
        const suffix = (value || "").indexOf("+") !== -1 ? "+" : ""
        return {
            value: parseInt(value),
            label,
            suffix
        }
    })
}

export const setCountryInfo = (countryCode) => {
    if (typeof localStorage !== 'undefined') {
        if(countryCode.length > 0) {
            localStorage.setItem('countryCode', countryCode)
        } else {
            console.log("setting Code");
            localStorage.setItem('countryCode', 'IN') 
        }
    }
}

export const getCountryInfo = () => {
    if (typeof localStorage !== 'undefined') {
        return localStorage.getItem('countryCode')
    }
}

export const isCountryIndia = () => {
    return getCountryInfo() === 'IN';
}

export const isIndianJob = (jobLocationText = "") => {
    const INDIAN_LOCATIONS = ['India', 'Hyderabad', 'Gurugram', 'Bangalore', 'Noida', 'Pune'];
    const hasIndianState = INDIAN_LOCATIONS.filter(state => {
        return jobLocationText?.toLowerCase().includes(state.toLowerCase());
    }).length > 0;
    return hasIndianState
}

export const filterLocationBasedJobs = (jobListData = []) => {
    return (jobListData || []).filter((item) => {
        const jobKeyDetails = item?.node?.data?.body?.find(obj => obj?.__typename === "PrismicJobDescriptionPageBodyKeyDetails") || {};
        const { primary: { job_location = {} } } = jobKeyDetails;
        const { text: jobLocationText } = job_location;

        const isIndia = isCountryIndia();
        const indianJob = isIndianJob(jobLocationText);
        return isIndia ? indianJob : !indianJob
    });
}

export const fetchUserLocation = () => {
    const countryCodeExpression = /loc=([\w]{2})/;
    return fetch("https://www.cloudflare.com/cdn-cgi/trace").then(response => response.text()).
        then(data => {
            const response = (data || "").split("\n").reduce((prev, curr) => {
                const [key, value] = (curr || "").split("=");
                return {
                    ...prev,
                    [key]: value
                }
            }, {});
            const country = response.loc || countryCodeExpression.exec(data)[1];
            console.log("country1",country);
            setCountryInfo(country)
        });
}


export {
    getColor,
    replaceCharAt,
    createUrl,
    isParamPresentInURL,
    getFormattedDate,
    camelize,
    checkCorporateEmail,
    mapCounterNumbers
}